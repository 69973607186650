import { Controller } from "@hotwired/stimulus";

// controller: "accounts--calc-tests--table-tree"
export default class extends Controller {
  static targets = ["row"];

  connect() {
    this.closeAll();
  }

  toggle(event) {
    const row = event.currentTarget;

    if (row.dataset.isParent === "true") {
      const isClosing = row.classList.contains("closed");
      row.classList.toggle("open", isClosing);
      row.classList.toggle("closed", !isClosing);

      const depth = parseInt(row.dataset.depth, 10);
      this.toggleChildren(row.dataset.path, depth, isClosing);
    }
  }

  closeAll() {
    this.rowTargets.forEach((row) => {
      const isParent = row.dataset.isParent === "true";
      row.classList.remove("open");
      row.classList.toggle("closed", isParent);
      row.classList.toggle("hidden", Number(row.dataset.depth) > 0);
    });
  }

  openAll() {
    this.rowTargets.forEach((row) => {
      row.classList.add("open");
      row.classList.remove("closed", "hidden");
    });
  }

  openEnquadramentos() {
    const firstEnquadramento = this.rowTargets.find((row) =>
      row.dataset.path.startsWith("itensDocFiscal") &&
      row.dataset.path.includes("enquadramentos")
    );

    if (firstEnquadramento) {
      this.expandRecursively("itensDocFiscal", "enquadramentos");
      firstEnquadramento.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

  toggleChildren(path, depth, isOpening, filter = null) {
    this.rowTargets.forEach((childRow) => {
      const childDepth = parseInt(childRow.dataset.depth, 10);
      const matchesFilter = !filter || childRow.dataset.path.includes(filter);

      if (
        childRow.dataset.path.startsWith(`${path}.`) &&
        childDepth === depth + 1 && // Apenas o próximo nível de filhos
        matchesFilter
      ) {
        if (isOpening) {
          childRow.classList.remove("hidden");
        } else {
          childRow.classList.add("hidden");
        }
      }
    });
  }

  expandRecursively(basePath, filter = null) {
    this.rowTargets.forEach((row) => {
      const matchesFilter = !filter || row.dataset.path.includes(filter);

      if (row.dataset.path.startsWith(`${basePath}.`) && matchesFilter) {
        row.classList.remove("hidden");
        row.classList.add("open");
        row.classList.remove("closed");
      }
    });
  }
}
